<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col
                class="text-center"
            >
                <v-icon
                    size="100"
                >
                    mdi-alert-circle
                </v-icon>
                <div>
                    <h1>{{ $t('customerportal-private-accessDenied') }}</h1>

                    <p>{{ $t('customerportal-private-accessDenied-info') }}</p>
                    <p>{{ $t('customerportal-private-accessDenied-info2') }}</p>
                </div>
                <v-btn
                    class="my-2"
                    :to="{ name: 'Login' }"
                >
                    {{ $t('customerportal-goToLogin') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    computed: {

        companyName(){
            return process.env.VUE_APP_TERMS_COMPANY_NAME
        },
    }
}
</script>